<template>
  <div :class="{ 'people-card': true, ventures: props.item.showAsManattVentureTeam }">
    <GenericCard :card-url="navigateUrl" :center-align-body="props.item.showAsManattVentureTeam">
      <template #image>
        <div
          ref="cardContainer"
          class="card-container"
          :class="props.item.showAsManattVentureTeam ? 'ventures' : ''"
        >
          <NuxtImg
            v-if="props.item.peopleImageUrl ?? '' != ''"
            :src="reactiveImageUrl"
            class="image"
            :class="props.item.showAsManattVentureTeam ? 'ventures' : ''"
            loading="lazy"
          />
          <img
            v-else
            alt="Placeholder Image"
            class="image"
            :class="props.item.showAsManattVentureTeam ? 'ventures' : ''"
            src="/assets/img/people-placeholder.png"
          />
        </div>
      </template>
      <template #body v-if="!props.item.showAsManattVentureTeam">
        <div class="role">
          <span>
            {{ first(item.position) }}
          </span>
        </div>
        <h4 class="name" style="cursor: pointer" @click="navigateTo(navigateUrl)">
          <b>{{ item.displayName }}</b>
        </h4>
        <div v-if="locationTitle.length > 0" class="location">
          <NuxtLink :to="navigateLocationUrl"> {{ locationTitle }}</NuxtLink>
        </div>
        <button
          :class="`btn btn-light d-flex align-items-end show-info pb-0 ${contactCollapsed ? 'collapsed' : ''}`"
          :data-bs-target="'#contactInfo-' + item.id"
          data-bs-toggle="collapse"
          style="height: 36px"
        >
          <span>Contact Information</span>
          <IconCaretDown v-if="contactCollapsed" />
          <IconCaretUp v-else />
        </button>
        <div :id="'contactInfo-' + item.id" ref="contact" class="collapse show-info-div">
          <div class="show-info-div__phone" @click="handlePhoneClick">
            <IconPhone />
            <span v-html="item.phone" />
          </div>
          <div class="show-info-div__email" @click="handleEmailClick">
            <IconEmail />
            <span v-html="item.email" />
          </div>
          <div class="show-info-div__vcard" @click="handleVCardClick">
            <IconIdentificationCard />
            <span>Download vCard</span>
          </div>
        </div>
      </template>
      <template #body v-else>
        <h4 class="name" style="cursor: pointer" @click="navigateTo(navigateUrl)">
          <b>{{ item.displayName }}</b>
        </h4>
        <div class="role">
          <span>
            {{ item.positionTitle }}
          </span>
        </div>
      </template>
    </GenericCard>
    <div class="view-more" v-if="props.item.showAsManattVentureTeam">
      <NuxtLink :to="navigateUrl"> View More <IconArrowRight /></NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SearchResultPeopleItem } from '~/types/SearchResultItem'
import first from 'lodash/first'
import { useEventListener } from '@vueuse/core'
import type { LocationListModel } from '~/models'
import { useElementSize } from '@vueuse/core'
import IconArrowRight from '../icons/IconArrowRight.vue'

const locationStore = useLocationStore()
const pageStore = usePageStore()
const props = defineProps<{ item: SearchResultPeopleItem }>()

const handlePhoneClick = () => {
  if (props.item.phone) {
    window.open(`tel:${props.item.phone}`, '_blank')
  }
}

const handleEmailClick = () => {
  if (props.item.email) {
    window.open(`mailto:${props.item.email}`, '_blank')
  }
}

const handleVCardClick = () => {
  if (props.item.vCardUrl) {
    window.open(props.item.vCardUrl, '_blank')
  }
}

const navigateUrl = ref<string>('')
pageStore.getUrlByItemId(props.item.id).then((url) => {
  if (props.item.showAsManattVentureTeam)
    navigateUrl.value = `/manatt-ventures/about/our-team/${props.item.urlSlug}`
  else if (url) navigateUrl.value = url
})

const navigateLocationUrl = ref<string>('')
if (props.item.location) {
  const locationsResponse = await locationStore.getLocations()
  navigateLocationUrl.value =
    locationsResponse?.find((x) => x.system.codename == props.item.location[0])?.elements.urlSlug
      ?.value || ''
}

const locationList = ref<LocationListModel[]>([])
const locationFetch = await fetchLocations()
if (locationFetch) {
  locationList.value = locationFetch.data?.items as LocationListModel[]
}

const locationTitle = ref<string>('')

if (props.item.location) {
  const locationsResponse = await locationStore.getLocations()
  locationTitle.value =
    locationsResponse?.find((x) => x.system.codename == props.item.location[0])?.elements?.title
      ?.value || ''
}

const contactCollapsed = ref<boolean>(true)
const contact = ref<HTMLElement | null>(null)

const cardContainer = ref<HTMLElement>()
const { width } = useElementSize(cardContainer)
const { reactiveImageUrl } = useImageSourceReactive(props.item.peopleImageUrl, width, width)

onMounted(() => {
  if (!contact.value) return
  useEventListener(contact.value, 'show.bs.collapse', () => (contactCollapsed.value = false))
  useEventListener(contact.value, 'hide.bs.collapse', () => (contactCollapsed.value = true))
})
</script>
