// stores/pardot-modal.ts
import { defineStore } from 'pinia'
import type { LocationPageModel } from '~/models'

interface ModalState {
    locations: LocationPageModel[]
}

export const useLocationStore = defineStore('locationsStore', {
  state: (): ModalState => ({
    locations: []
  }),
  actions: {
    async getLocations() {
        if(this.locations.length > 0){
            return this.locations;
        }
        const response = await fetchLocations()
        if(response?.status == 200){
            this.locations = response.data?.items as LocationPageModel[]
        }
    }
  }
})
